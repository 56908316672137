import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function CloudScannerMobile({ show, setShow }) {
  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered  modalTitle={""}>
      <Modal.Header closeButton style={{border:'none', background:'#e9edf5'}}>
      </Modal.Header>

      <Modal.Body style={{background:'#e9edf5', color:"rgba(18, 20, 31, 1)", fontFamily: 'Work Sans'}}>
        <h2 style={{maxWidth:'30ch', textAlign:'center'}}>
          This page is not optimized for use on mobile phones 📱🚫.
           Please consider using a wider screen device 💻.
        </h2>
      </Modal.Body>

      <Modal.Footer style={{border:'none', background:'#e9edf5'}}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CloudScannerMobile;
