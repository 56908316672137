// /* eslint-disable no-unused-vars */
import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useMemo,
  useContext,
} from "react";
import ReactFlow, {
  Background,
  applyEdgeChanges,
  applyNodeChanges,
  addEdge,
  Panel,
  ReactFlowProvider,
  getRectOfNodes,
  getTransformForBounds,
} from "reactflow";
import "reactflow/dist/style.css";
import "./canvas.component.css";
import allNodeTypes from "../new-icons/all-icons";
import CustomEdge from "./CustomEdge";
import "react-tabs/style/react-tabs.css";
import SidePanelModal from "./component/SidePanelModal";
import { toPng } from "html-to-image";
import NewGraphDetail from "./component/NewGraphDetail";
import ZoomInOut from "../new-view-edit-architecture/components/ZoomInOut/ZoomInOut";
import FooterBar from "../new-view-edit-architecture/FooterBar";
import SpinnerLoader from "../../../common/components/SpinnerLoader/SpinnerLoader";
import context from "../../../store/create-context";
import { getHighlightEdgePathFn } from "./utils/hightlightNodeEdges";
import { CalulateNodesXYPosition } from "./utils/elk";
import { groupNodes } from "./utils/groupNodes";
import NodeEdgePopUp from "./component/NodeEdgePopUp";
import { get_visualization } from "./utils/get_visualization";
import NewNode from "./NewNode";
import api from "../../../common/api";
import { handleZoomOut, handleZoomIn, timeSpent, trackNodeEdgeClick, handleDoubleClickOnParentNode } from "../../../utils/google-analytics";
import CreateContext from "../../../store/create-context";
import InfraStructure from "./InfraStructure";
import awsNodeTypes from "../new-icons/aws-icons";
import FeedbackButton from "../new-view-edit-architecture/FeedbackButton";
import SearchBar from "./SearchBar";
//Variables
let interval;
let zoomDuration = 800;
const edgeTypes = { custom: CustomEdge };

const Canvas = forwardRef((props, ref) => {
  const { userId, setShowToastr, isAnyApplicationSelected, setIsAnyApplicationSelected,
     matchedNodeDetail, showAddNewNodeModal, setShowAddNewNodeModal} = useContext(CreateContext);
  //Context
  const ctx = useContext(context);

  /**
   * STATES START
   * ======================================================
   */

  const [graphNodes, setGraphNodes] = useState([]);
  // const [graphNodes,setGraphNodes, {undo, redo, reset} ] = useUndoable([]);
  const [nodeDragStartStop, setNodeDragStartStop] = useState([]);
  const [graphEdges, setGraphEdges] = useState([]);
  const [azureNodes, setAzureNodes] = useState([]);
  const [azureEdges, setAzureEdges] = useState([]);
  const [onpremNodes, setOnpremNodes] = useState([]);
  const [onpremEdges, setOnpremEdges] = useState([]);
  const [zoomValue, setZoomValue] = useState(0);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedAssociation, setSelectedAssociation] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  // 0 - none, 1 - node, 2 - edge
  const [selectedComponent, setSelectedComponent] = useState(-1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // 0 - aws, 1 - azure, 2 - gcp, 3 - on-prem, 4 - hybrid
  const [violations, setViolations] = useState("");
  const [loadingSecurity, setLoadingSecurity] = useState(false);
  const [violationsLoaded, setViolationsLoaded] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [isSidePanelModalOpen, setIsSidePanelModalOpen] = useState(false);
  const [selectedNodeForPanel, setSelectedNodeForPanel] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] = useState("");
  const [graphDetail, setGraphDetail] = useState([]);
  const [highlightEdges, setHightlightEdges] = useState({});
  const [highlightNodes, setHightlightNodes] = useState([]);
  const [isGraphLoaded, setIsGraphLoaded] = useState(false);
  const [target, setTarget] = useState(null);
  const [color, setColor] = useState("");
  const [edgeTarget, setEdgeTarget] = useState();
  const [redoNodes, setRedoNodes] = useState([]);
  const [addNodeModalPosition, setAddNodeModalPosition] = useState({});
  const [showNewNode, setShowNewNode] = useState(false);
  const [reactFlowInstanceNodes, setReactFlowInstanceNodes] = useState("");
  const [keyPressed, setKeyPressed] = useState({});
  const [selectNodeForInfra, setSelectNodeForInfra] = useState({});
  const [searchBar, setSearchbar] = useState("");
  const [selectNodeForLinking, setSelectNodeForLinking] = useState(null)
  const [open, setOpen] = useState(false);
  const initialNodeDetail ={
    name:"",
    description:"",
    type:""
  }
  const [newNodeDetail, setNewNodeDetail] = useState(initialNodeDetail);
  useEffect(() => {
    const startTime = new Date().getTime();
    return () => {
      const endTime = new Date().getTime();
      let timeDifferenceMS = endTime - startTime;
      var msPerMinute = 60 * 1000;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      let duration;
      if (timeDifferenceMS < msPerMinute) {
        duration = Math.round(timeDifferenceMS / 1000) + " seconds";
      }
      else if (timeDifferenceMS < msPerHour) {
        duration = Math.round(timeDifferenceMS / msPerMinute) + " minutes";
      }
      else if (timeDifferenceMS < msPerDay) {
        duration = Math.round(timeDifferenceMS / msPerHour) + " hours";
      }
      timeSpent(duration, "Canvas", userId);
    };
  }, []);

  /**
   * STATES END
   * ======================================================
   */

  const onGraphNodesChange = useCallback(
    (changes) => setGraphNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onGraphEdgesChange = useCallback(
    (changes) => setGraphEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params) => setGraphEdges((els) => addEdge(params, els)),
    []
  );

  function display_graph(raw_graph) {
    return raw_graph["edges"] === undefined || raw_graph["nodes"] === undefined ? false : true;
  }

  function getViewData(view) {
    return props.sResponse[view] ?? props.sResponse;
  }

  const handleSidePanelOpen = () => {
    setIsSidePanelModalOpen(true);
  };

  const handleSidePanelClose = () => {
    setIsSidePanelModalOpen(false);
  };

  const highlightNodesFn = (arr = []) => {
    setHightlightNodes((prev) => {
      return prev[0] === arr[0] ? [] : [...arr]});
  };

  const highlightOnNodeClick = (node) => {
    setHightlightNodes(() => node);
  };

  const handleNodeDragStart = (ev, node) => {
    setNodeDragStartStop((prev) => [...prev, node]);
  };

  const handleNodeDragStop = (ev, node) => {
    setNodeDragStartStop((prev) => {
      const allChangesNodes = prev.slice(0, prev.length - 1);
      const latestChangeNode = prev[prev.length - 1];
      return [
        ...allChangesNodes,
        { ...latestChangeNode, stopPosition: node.position },
      ];
    });
  };
  // undo the node position functionality start
  const handleUndo = () => {
    setNodeDragStartStop((prev) => {
      const undoNode = prev[prev.length - 1];
      setRedoNodes((prev) => [...prev, undoNode]);
      setGraphNodes((prevGrapNodes) =>
        prevGrapNodes.map((node) => {
          if (node.id === undoNode?.id) {
            let n = {
              ...prevGrapNodes.filter((ele) => ele?.id === undoNode.id)[0],
              position: undoNode.position,
            };
            return n;
          } else {
            return node;
          }
        })
      );
      return prev.slice(0, -1);
    });
  };

  // redo the node position:-
  const handleRedo = () => {
    setRedoNodes((prev) => {
      const redoNode = prev[prev.length - 1];
      setNodeDragStartStop((prev) => [...prev, redoNode]);
      setGraphNodes((prevGrapNodes) =>
        prevGrapNodes.map((node) => {
          if (node.id === redoNode?.id) {
            let n = {
              ...prevGrapNodes.filter((ele) => ele?.id === redoNode.id)[0],
              position: redoNode.stopPosition,
            };
            return n;
          } else {
            return node;
          }
        })
      );
      return prev.slice(0, -1);
    });
  };
  // undo the node position functionality end

  /**
   * ************************************
   *  ZOOM ON SCROLL
   * ************************************
   */
  const onWheel = (event) => {
    if (event.target.closest(".react-flow__pane")) {
      //Zoom factor
      let zoomFactor = event.deltaY > 0 ? -0.1 : +0.1;
      if (zoomFactor === -0.1){
        handleZoomOut(userId);
      }
      if (zoomFactor === 0.1){
        handleZoomIn(userId);
      }
      clearTimeout(interval);
      interval = setTimeout(() => {
        //New Zoom
        let newZoom = zoomValue + zoomFactor;
        newZoom = Math.min(Math.max(0.5, newZoom), 2);
        newZoom = Number(newZoom.toFixed(1));
        newZoom = isNaN(newZoom) ? 0.5 : newZoom;
        //set states
        if (newZoom !== zoomValue) {
          setZoomValue(newZoom);
          ctx.setZoomVal(newZoom);
          reactFlowInstance.zoomTo(newZoom, { duration: zoomDuration });
        }
      }, 100);
    }
  };

  /**
   * ************************************
   *  ZOOM IN OUT COMPONENT
   * ************************************
   */
  const ZoomInOutComponent = useMemo(
    () => (
      <ZoomInOut
        zoomValue={zoomValue}
        setZoomValue={setZoomValue}
        zoomTo={reactFlowInstance.zoomTo}
        handleUndo={handleUndo}
        handleRedo={handleRedo}
        color={color}
        setColor={setColor}
        fitView={() => {
          let view_data = getViewData(ctx.activeSection);
          if (view_data !== "") {
            let RAW_NODE = null;
            if (graphDetail.length > 1) {
              let currentGraph = graphDetail[graphDetail.length - 1];
              if (view_data.nodes) {
                RAW_NODE = view_data.nodes.filter(
                  (view_node) => view_node.name === currentGraph.name
                )[0];
              }
            }
            RAW_NODE === null
              ? renderGraph(view_data)
              : RAW_NODE.sub_graph && renderGraph(RAW_NODE.sub_graph);
          }
        }}
      />
    ),
    [zoomValue]
  );

  /**
   * ************************************
   *  FOOTER BAR COMPONENT
   * ************************************
   */
  const FooterBarComponent = useMemo(
    () => (
      <FooterBar
        {...props}
        items={props.items}
        setItems={props.setItems}
        searchLoader={props.searchLoader}
        setSearchLoader={props.setSearchLoader}
      />
    ),
    [ctx.showBars.showFooter]
  );

  /**
   * ************************************
   *  RIGHT SIDEBAR COMPONENT
   * ************************************
   */
  const SidePanelModalComponent = useMemo(
    () =>
      selectedNodeForPanel && (
        <SidePanelModal
          isOpen={isSidePanelModalOpen}
          onClose={handleSidePanelClose}
          setSelectedNode={setSelectedNode}
        >
          <h1 style={{ textTransform: "capitalize", marginTop: "60px" }}>
            {selectedNodeForPanel.name}
          </h1>
          <hr />
          <p>{selectedNodeForPanel.info}</p>
          {selectedNodeForPanel.rurl ? (
            <a
              href={selectedNodeForPanel.rurl}
              target="_blank"
              rel="noreferrer"
            >
              Resource: {selectedNodeForPanel.rid}
            </a>
          ) : selectedNodeForPanel.rid ? (
            <span>Resource: {selectedNodeForPanel.rid}</span>
          ) : null}
        </SidePanelModal>
      ),
    [selectedNodeForPanel, isSidePanelModalOpen]
  );

  /**
   * ************************************
   * GRAPH DETAIL COMPONENT
   * ************************************
   */

  const GraphDetailComponent = useMemo(
    () => (
      <NewGraphDetail
        graphDetail={graphDetail}
        backToParentGraph={moveToParentGraph}
      />
    ),
    [graphDetail]
  );

  /**
   * ************************************
   *  NODES AND EDGES POPUP
   * ************************************
   */
  const NodeEdgePopUpComponent = useMemo(() => {
    /* eslint-disable */
    return selectedNode || selectedEdge ? (
      <NodeEdgePopUp
        target={target}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        selectedComponent={selectedComponent}
        selectedNode={selectedNode}
        selectedEdge={selectedEdge}
        selectedAssociation={selectedAssociation}
        highlightNodesFn={highlightNodesFn}
        setSelectedNode={setSelectedNode}
      />
    ) : (
      <></>
    );
  }, [selectedNode, selectedEdge]);

  /**
   * ************************************
   *  HIGHLIGHT NODES AND EDGES START
   * ************************************
   */
  useEffect(() => {
    const { key, nodes } = ctx.highlightEdgesContext;

    if (key == null) {
      setHightlightEdges({});
      return;
    }

    if (highlightEdges.hasOwnProperty(key)) {
      delete highlightEdges[key];
      setHightlightEdges({});
      return;
    }

    if (Array.isArray(nodes) && nodes.length > 0) {
      let { selectedNodes, selectedEdges } = getHighlightEdgePathFn(
        nodes,
        graphEdges
      );

      if (selectedNodes.length > 0 && selectedEdges.length > 0) {
        setHightlightEdges({
          [key]: { nodes: selectedNodes, edges: selectedEdges },
        });
      }
    }
    /* eslint-disable */
  }, [ctx.highlightEdgesContext]);

  useEffect(() => {
    if (ctx.highlightNodesContext.length > 0) {
      highlightNodesFn(ctx.highlightNodesContext);
      ctx.setHightlightNodesContext([]);
    }
  }, [ctx.highlightNodesContext]);

  useEffect(() => {
    setGraphNodes((prev) => {
      return prev.map((node) => {
        if (highlightNodes.includes(node.id)) {
          node.data.style.borderColor = "#00B2D9";
          node.data.style.borderWidth = "4px";
          node.data.style.backgroundColor = "rgb(96 96 96)"
        } else {
          node.data.style.borderColor = "#4E4E4E";
          node.data.style.borderWidth = "1px";
          node.data.style.backgroundColor = "#181818";
        }
        return node;
      });
    });
  }, [highlightNodes]);

  useEffect(() => {
    let nodes = [];
    let edges = [];

    for (let _k in highlightEdges) {
      if (
        highlightEdges[_k]["nodes"] &&
        Array.isArray(highlightEdges[_k]["nodes"])
      ) {
        nodes = [...nodes, ...highlightEdges[_k]["nodes"]];
      }

      if (
        highlightEdges[_k]["edges"] &&
        Array.isArray(highlightEdges[_k]["edges"])
      ) {
        edges = [
          ...edges,
          ...highlightEdges[_k]["edges"].map((edge) => edge.id),
        ];
      }
    }

    setGraphNodes((prev) => {
      return prev.map((node) => {
        node.data.style.borderColor = nodes.includes(node.id)
          ? "#00B2D9"
          : "#4E4E4E";
        node.data.style.borderWidth = nodes.includes(node.id) ? "2px" : "1px";
        return node;
      });
    });

    setGraphEdges((prev) => {
      return prev.map((edge) => {
        edge.data.color = edges.includes(edge.id) ? "#00B2D9" : "white";
        edge.data.strokeWidth = edges.includes(edge.id) ? 2 : 1;
        return edge;
      });
    });
  }, [highlightEdges]);

  /**
   * ************************************
   *  HANDLE NODE AND EDGE MOUSE LEFT CLICK
   * ************************************
   */
  const handleNodeClick = (event, node) => {
    matchedNodeDetail.map((item) => {
      if(item === node.data.label){
        const nodePosition = document.getElementsByClassName("label" + item + "_node");
        setTarget(nodePosition[0]);
      }
    })
    event.stopPropagation();
    trackNodeEdgeClick(userId,"node");
    //Populate leftsidebar analysis search bar with node name
    ctx.setSearchNode(node.name);
    if (isSidebarOpen && selectedNodeId === node.id) {
      setIsSidebarOpen(false);
      highlightOnNodeClick([]);
      setSelectedNode(null);
      setTarget(null);
      setSelectedComponent(0);
      setSelectedNodeId(null);
    } else {
      setIsSidebarOpen(true);
      highlightOnNodeClick([node.id]);
      setSelectedNode(node);
      setSelectedComponent(1);
      setSelectedNodeId(node.id);
    }
  };

  const handleEdgeClick = (event, edge) => {
    trackNodeEdgeClick(userId,"edge");
    if (isSidebarOpen && selectedEdge?.id === edge.id) {
      event.target.style.border = "0px";
      setIsSidebarOpen(false);
      setSelectedEdge(null);
      setSelectedComponent(0);
    } else {
      if (edgeTarget) {
        setEdgeTarget((prev) => (prev.style.border = "0px"));
      }
      if (event.target.style.pointerEvents) setIsSidebarOpen(true);
      event.target.style.border = "1px solid rgb(0 178 217 / 94%)";
      setTarget(event.target);
      setEdgeTarget(event.target);
      setSelectedEdge(edge);
      setSelectedComponent(2);
    }
  };

  /**
   * ************************************
   *  FIT THE GRAPH TO CANVAS
   * ************************************
   */
  useEffect(() => {
    if (reactFlowInstance && isGraphLoaded) {
      reactFlowInstance.fitView({ duration: 800 });
      takeSnapShot();
      setTimeout(() => {
        let fitViewZoomVal = reactFlowInstance.getZoom();
        setReactFlowInstanceNodes(reactFlowInstance.getNodes());
        setZoomValue(fitViewZoomVal);
        ctx.setZoomVal(fitViewZoomVal);
        setIsGraphLoaded(false);
      }, [800]);
    }
  }, [isGraphLoaded, reactFlowInstance]);

  /**
   * ************************************
   *  RIGHT CLICK HANDLER FOR NODE
   * ************************************
   */
  const handleNodeContextMenu = (event, node) => {
    event.preventDefault();
    setSelectNodeForLinking(node)
    setSelectedNodeId(node.id);
    setSelectNodeForInfra({
      "node-type": node.type,
      subgraph: node.data.has_subgraph,
    });
    if (!showNewNode) {
      setShowAddNewNodeModal(node);
    }
  };

  /**
   * ************************************
   *  GRAPH RENDER HANDLER
   * ************************************
   */

  const renderGraph = (view_data) => {
    if (view_data !== "" && display_graph(view_data)) {
      let graph_viz = get_visualization(view_data);
      (async function () {
        try {
          const { nodes: layoutedNodes, edges: layoutedEdges } =
            await CalulateNodesXYPosition(graph_viz.nodes, graph_viz.edges);
          //Group Nodes Start
          //=======================================================
          graph_viz.nodes = layoutedNodes;
          graph_viz.edges = layoutedEdges;
          graph_viz = groupNodes(graph_viz);
          // Mark the graph as loaded
          setIsGraphLoaded(true);
          //=======================================================
          //Group Nodes End
          if (view_data["cloud_provider"] === "Azure") {
            setAzureNodes(graph_viz.nodes);
            setAzureEdges(graph_viz.edges);
          } else if (view_data["cloud_provider"] === "Onprem") {
            setOnpremNodes(graph_viz.nodes);
            setOnpremEdges(graph_viz.edges);
          } else {
            // Default to AWS
            setGraphNodes(graph_viz.nodes);
            setGraphEdges(graph_viz.edges);
          }
        } catch (error) {
          console.log({ error });
        }
      })();
    } else {
      //Default to AWS
      setGraphNodes([]);
      setGraphEdges([]);
    }

    props.socket.addEventListener("violations", (event) => {
      setLoadingSecurity(false);
      setViolations(event["violations"]);
      setViolationsLoaded(true);
    });
  };

  /**
   * ************************************
   *  GRAPH RENDER FOR ACTIVE SECTION
   * ************************************
   */
  useEffect(() => {
    /**
     * GET GRAPH DETAIL ACCORDING TO VIEW SELECTED
     */
    let view_data = getViewData(ctx.activeSection);
    setIsAnyApplicationSelected(false);
    props.setSidebarData(view_data);
    renderGraph(view_data);
    view_data["name"] &&
      setGraphDetail([{ name: view_data["name"], active: true }]);
  }, [props.sResponse, ctx.activeSection]);

  // close node pop-up layout changes
  useEffect(() => {
    handleCloseSidebar();
  }, [
    ctx.activeSection,
    zoomValue,
    ctx.showBars,
    ctx.highlightEdgesContext,
    ctx.footerBtn,
    ctx.showDocSidebarCanvas,
  ]);

  /**
   * ************************************
   *  HANDLE NODE DOUBLE CLICK
   * ************************************
   */
  const handleDoubleClick = (event, node) => {
    event.preventDefault();
    // find the node and the subgraph associated with it
    // if there is subgraph, then display it. otherwise, display the side panel

    /**
     * GET GRAPH DETAIL ACCORDING TO VIEW SELECTED
     */
    let view_data = getViewData(ctx.activeSection);

    let RAW_NODE = null;
    if (view_data.nodes) {
      RAW_NODE = view_data.nodes.filter(
        (view_node) => view_node.id === node.id
      )[0];
    }
    if (RAW_NODE != null && RAW_NODE.sub_graph) {
      handleDoubleClickOnParentNode(userId);
      renderGraph(RAW_NODE.sub_graph);
      // props.setSidebarData(RAW_NODE.sub_graph);
      props.setSidebarData(view_data);
      setGraphDetail((prev) => {
        return [
          { ...prev[0], active: false },
          { name: node.name ?? "", active: true },
        ];
      });
    } else {
      setSelectedNodeForPanel(node);
      handleSidePanelOpen();
    }
  };
  const keyDownHandler = useCallback((e) => {
    setKeyPressed((prev) => ({...prev, [e.key]: true}));
    if (e.keyCode === 90 && e.ctrlKey && !(e.shiftKey)) {
      handleUndo();
    }
    if(e.keyCode === 90 && e.ctrlKey && e.shiftKey){
       handleRedo();
    }
    if(selectedNode !== null && e.key === 'Enter'){
     handleDoubleClick(e, selectedNode)
    }
    if(e.keyCode === 27){
      moveToParentGraph();
    }
  }, [ moveToParentGraph ]);

  const keyUpHandler = () => {
    if(ctx.activeSection ==="code_view" && keyPressed.a && keyPressed.n){
      setShowNewNode(true);
      setShowAddNewNodeModal(null);
    }
    setKeyPressed({});
  }
  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    document.addEventListener("keyup", keyUpHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
      document.removeEventListener("keyup", keyUpHandler);
    };
  }, [ moveToParentGraph ]);

  /**
   * ************************************
   *  HANDLE MOVE TO PARENT GRAPH
   * ************************************
   */
  function moveToParentGraph() {
    /**
     * GET GRAPH DETAIL ACCORDING TO VIEW SELECTED
     */
    let view_data = getViewData(ctx.activeSection);
    renderGraph(view_data);
    props.setSidebarData(view_data);
    setGraphDetail((prev) => {
      return [{ ...prev[0], active: true }];
    });
  }

  // close node pop-up
  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedNode(null);
  };

  // set the Edge color according to background brightness


  const contextMenuhandler = (e) => {
    e.preventDefault(); // prevent the default behaviour when right clicked
    setAddNodeModalPosition((prev) => {
      return { ...prev, top: e.clientY, left: e.clientX };
    });
  };
  const addNewNode = (e) => {
    if (newNodeDetail.name !== "") {
      const newNode = {
        id: "aad" + Math.random(),
        name: "",
        description:`${newNodeDetail.description}`,
        type:`${awsNodeTypes.hasOwnProperty(newNodeDetail.type) ? newNodeDetail.type : "cog"}`,
        data: {
          label: `${newNodeDetail.name}`,
          node_type:`${newNodeDetail.type}`,
          size: 80,
          handles: [
            {
              type: "source",
              position: "right",
              id: `aad-source`,
            },
            {
              type: "target",
              position: "left",
              id: `aad-target`,
            },
            {
              type: "source",
              position: "top",
              id: `add-source`,
            },
            {
              type: "source",
              position: "bottom",
              id: `add-target`,
            },
          ],
          style: {
            color: "#14dbc7",
            borderColor: "#4E4E4E",
            borderWidth: "1px",
            backgroundColor: "#181818",
          },
          size: "80",
        },
        position: {
          x: addNodeModalPosition.left,
          y: addNodeModalPosition.top,
        },
        height: "110",
        width: "140",
        x: addNodeModalPosition.left,
        y: addNodeModalPosition.top,
      };
      setGraphNodes((prev) => {
        return [...prev, newNode];
      });
      setNewNodeDetail(initialNodeDetail);
      setShowNewNode(false);
    }
  };
  const handleOutsideClick = () => {
    setHightlightNodes([])
    setShowAddNewNodeModal(null);
    setShowNewNode(false);
    setNewNodeDetail(initialNodeDetail);
  };

  const uploadFile = async (file, projectId) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("project_id", projectId);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    };
    return await api.post(`/uploadImage`, formData, config);
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
}

  async function downloadImage(dataUrl) {
    var file = dataURLtoFile(dataUrl, props.projectName + ".png");
    const projectId = sessionStorage.getItem("jml_workspace_id");
    try {
      const response = await uploadFile(file, projectId);
    } catch (error) {
      console.error("Error in upload image: ", error)
      setShowToastr({
        show: true,
        type: "error",
        text: error?.response?.data?.error
      });
    }
  }

  const imageWidth = 1024;
  const imageHeight = 768;

  const takeSnapShot = () => {
    const nodesBounds = getRectOfNodes(reactFlowInstance.getNodes());
    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);
    if(document.querySelector(".react-flow__viewport")){
      toPng(document.querySelector(".react-flow__viewport"), {
        backgroundColor: 'black',
        width: imageWidth,
        height: imageHeight,
        style: {
          width: imageWidth,
          height: imageHeight,
          transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
        },
        skipFonts: true,
      }).then(downloadImage)
      .catch(err =>{
        console.log("error is",err);
      })
    }
  };

  const handleNavView = () => {
    setSearchbar(selectNodeForInfra["node-type"])
    setOpen(true)
  }

  return (
    <>
    <div id="arch_atf_btf" style={{ width: "100%", height: "100%", background: `${ctx.activeSection === "infra_view" ?"rgba(18,20,31,8)" :""}`}} onClick={handleOutsideClick}>
      <div
        id="rf_div"
        className="w-100 h-100"
        style={{ position: "relative", height: "100vh", background: color }}
      >
      <div className="adjacent-component">
        {
          ctx.activeSection === "infra_view" &&
            <InfraStructure
              infraViewResponse={props.sResponse.infra_view}
            />
        }
          {(isAnyApplicationSelected || ctx.activeSection !== "infra_view") && <ReactFlowProvider>
            <Background size="1.8" gap="50" color="gray" variant="dots" />
            <ReactFlow
              onInit={(instance) => {
                setReactFlowInstance(instance);
              }}
              onNodeDoubleClick={handleDoubleClick}
              onNodeContextMenu={handleNodeContextMenu}
              nodeTypes={allNodeTypes}
              nodes={graphNodes}
              edges={graphEdges}
              edgeTypes={edgeTypes}
              onConnect={onConnect}
              onNodeDragStart={handleNodeDragStart}
              onNodeDragStop={handleNodeDragStop}
              onNodesChange={onGraphNodesChange}
              onEdgesChange={onGraphEdgesChange}
              onNodeClick={handleNodeClick}
              onEdgeClick={handleEdgeClick}
              proOptions={{ hideAttribution: true }}
              fitview="true"
              zoomOnScroll={false}
              onWheel={onWheel}
              onPaneClick={handleCloseSidebar}
              onNodeDrag={handleCloseSidebar}
              onContextMenu={(e) => contextMenuhandler(e)}
            >
              {graphNodes.length > 0 && (
                <Panel position="bottom-right">{ZoomInOutComponent}</Panel>
              )}
              <Panel position="top-left" style={{ margin: "1rem 4rem" }}>
                {GraphDetailComponent}
              </Panel>
            </ReactFlow>
          </ReactFlowProvider>}
          <SpinnerLoader />
        </div>
        {ctx.activeSection === "code_view" &&
          <SearchBar
           nodes={props.sResponse?.code_view.nodes}
           handleNodeClick={handleNodeClick}
           selectNodeForInfra ={selectNodeForInfra}
           setSelectNodeForInfra = {setSelectNodeForInfra}
           searchBar={searchBar}
           setSearchbar={setSearchbar}
           selectNodeForLinking={selectNodeForLinking}
           infraViewResponse={props.sResponse.infra_view}
           setOpen={setOpen}
           open={open}
          />}
        {!props.sandboxMode && (
          <div style={{position:'absolute', bottom:'75px', right:'220px'}}>
            <FeedbackButton/>
          </div>
        )}

        {FooterBarComponent}

        {isSidebarOpen && NodeEdgePopUpComponent}

        {SidePanelModalComponent}

        {/* {graphNodes.length === 0 && <DefaultCanvasModal {...props} />} */}
      </div>
    </div>
      {showAddNewNodeModal && showAddNewNodeModal?.id && ctx.activeSection !== "infra_view" && (
        <div
          className="nodeModalClass"
          style={{
            top: addNodeModalPosition.top,
            left: addNodeModalPosition.left,
          }}
        >
          <span
            style={{ pointerEvents: "all" }}
            onClick={() => {
              setShowNewNode(() => true);
              setShowAddNewNodeModal(null);
            }}
            className="add-node-edge"
          >
            Add node
          </span>
          <span
            style={{ pointerEvents: "all"}}
            className="add-node-edge"
          >
            Add edge
          </span>
          {selectNodeForInfra && !selectNodeForInfra.subgraph && props.sResponse.hasOwnProperty("infra_view") && (
            <span
              onClick={handleNavView}
              style={{ pointerEvents: "all", border: "none"}}
              className="add-node-edge"
            >
              Link Infra Resource
            </span>
          )}
        </div>
      )}
      {showNewNode && ctx.activeSection !== "infra_view" && (
        <NewNode
          left={addNodeModalPosition.left}
          top={addNodeModalPosition.top}
          newNodeDetail={newNodeDetail}
          setNewNodeDetail={setNewNodeDetail}
          addNewNode={addNewNode}
        />
      )}
    </>
  );
});

export default Canvas;