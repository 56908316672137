import { BrowserRouter as Router, Routes, Route , useNavigate} from "react-router-dom";

import "./App.css";
import { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import WebSocketInstance from "./common/WebSocketInstance";
import RequireAuth from "./RequireAuth";
import axios from "axios";
import LoginPage from "./app/components/login/LoginPage";
import ChangePassword from "./app/components/changepassword/ChangePassword";
import NewDashboard from "./app/NewDashboard/NewDashboard";
import AdminRegisterPage from "./app/components/adminregister/AdminRegisterPage";
import RedriveIndexingPage from "./app/components/admintools/RedriveIndexing";
import NewSafeViewEditArchitecture from "./app/components/new-view-edit-architecture/new-safe-view-edit-architecture";
import UpdatedLanding from "./app/NewLanding2/Newlanding2";
import PageNotFound from "./PageNotFound";
import context from "./store/create-context";
import api from "./common/api";
import InPress from "./app/components/newLandingPages/InPress/inPress";
import Blogs from "./app/components/newLandingPages/blogs/Blogs";
import ProductPage from "./app/components/newLandingPages/ProductPage/productIndex";
import NewArchProductPage from "./app/components/newLandingPages/ProductPage/New/NewArch";
import CloudScannerProductPage from "./app/components/newLandingPages/ProductPage/CloudScanner/CloudScanner";
import CloudScannerUserGuide from "./app/components/newLandingPages/ProductPage/CloudScanner/CloudScannerUserGuide";
import BrochuresIndex from "./app/components/newLandingPages/brochures/brochuresIndex";
import FlyersIndex from "./app/components/newLandingPages/flyers/flyersIndex";

function App() {
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress"
  ];
  const [sResponse, setSResponse] = useState(""); // S=Server: this represents the actual graph we display
  const [qResponse, setQResponse] = useState(""); // Q=Questionnaire this is for questionnaire
  const [promptFixed, setPromptFixed] = useState(false);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [descriptionId, setDescriptionId] = useState(""); // uuid for the description
  const [socket, setSocket] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loadingArch, setLoadingArch] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [passwordChangeResponse, setPasswordChangeResponse] = useState()
  const { setProjects, userId, setUserId, setShowToastr } = useContext(context);
  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "dev";
  const navigate = useNavigate()
  async function handleLogin(username, password) {
    let url = process.env.REACT_APP_SIMPLIFAI_BACKEND_API_URL+"/login";

    isDevEnvironment && console.log("Sending login request for user", username, "to", url);

    try {
      const res = await axios.post(url, {username, password:password});
      if (res.data.success) {
        isDevEnvironment && console.log("The user is authenticated. First name and last name are", res.data.firstname, res.data.lastname);
        localStorage.setItem("JMLAuthToken", res.data.token);
        localStorage.setItem("JMLFirstname", res.data.firstname);
        localStorage.setItem("JMLLastname", res.data.lastname);
        localStorage.setItem("userId", res.data.user_id);
        setAuthToken(res.data.token);
        setUserId(res.data.user_id);
        const socket = WebSocketInstance.getInstance();
        setSocket(socket);
        return res.data.user_id;
      } else {
        isDevEnvironment && console.log("Authentication failed");
        return;
      }
    } catch (err) {
      if (err.response) {
        setShowToastr({
          show: true,
          type: "error",
          text: err?.response?.data?.message
        });
        console.error("Server responded with an error", err.response);
      } else {
        console.error("Something went wrong", err);
      }
      return null; // Returning null to indicate an error occurred
    }
  }

  async function handleAdminRegister(
    adminUsername,
    adminPassword,
    command,
    uEmail,
    uPassword,
    uFirstName,
    uLastName,
  ) {
    try {
      isDevEnvironment && console.log("Sending request to backend to register user with email " + uEmail + " and command " + command);
      const res = await axios.post(
        `${process.env.REACT_APP_SIMPLIFAI_BACKEND_API_URL}/adminregister`,
        {
          admin_user: adminUsername,
          admin_password: adminPassword,
          command: command,
          u_email: uEmail,
          u_password: uPassword,
          u_firstname: uFirstName,
          u_lastname: uLastName,
        }
      );
      isDevEnvironment && console.log(res.data.message);
      if (res.data.success) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      isDevEnvironment && console.log(err);
      setShowToastr({
        show: true,
        type: "error",
        text: err?.response?.data?.error ? err?.response?.data?.error : err?.response?.data?.message
      });
      return false; // returning false in case of an error
    }
  }

  async function handleRedriveIndexing(
    adminUsername,
    adminPassword,
    uEmail,
    projectId,
    activityId
  ) {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SIMPLIFAI_BACKEND_API_URL}/redriveindexing`,
        {
          admin_user: adminUsername,
          admin_password: adminPassword,
          u_email: uEmail,
          project_id: projectId,
          activity_id: activityId,
        }
      );
      if (res.data.success) {
        isDevEnvironment && console.log("Redrove indexing successfully");
        return true;
      } else {
        return false;
      }
    } catch (err) {
      isDevEnvironment && console.log(err);
      return false; // returning false in case of an error
    }
  }

  useEffect(() => {
    if (socket !== null) {
      socket.addEventListener("force_logout", (event)=>{
        setTimeout(logoutHandler,2000)
      })
      socket.addEventListener("architecture", (event) => {
        isDevEnvironment && console.log("Received architecture");
        if ("aws" in event["architecture"]) {
          isDevEnvironment && console.log(event["architecture"]["aws"]);
          setSResponse(event["architecture"]["aws"]);
        } else if ("azure" in event["architecture"]) {
          isDevEnvironment && console.log(event["architecture"]["azure"]);
          setSResponse(event["architecture"]["azure"]);
        }
        setPromptFixed(true);
        setWaitingForResponse(false);
      });
    }
  }, [socket]);

  useEffect(() => {
    if (authToken !== null) {
      isDevEnvironment && console.log("Creating websocket instance");
      const socket = WebSocketInstance.getInstance();
      setSocket(socket);
    } else {
      setSocket(null);
    }
  }, [authToken]);

  useEffect(() => {
    let JMLAuthToken = localStorage.getItem("JMLAuthToken");
    let userId = localStorage.getItem("userId");
    if (JMLAuthToken && JMLAuthToken !== "") {
      setAuthToken(JMLAuthToken);
    }
    if(userId && userId !== ""){
      setUserId(userId);
    }
  }, []);

  useEffect(() => {
    isDevEnvironment && console.log("Mounting App component");
    return () => {
      isDevEnvironment && console.log("Closing App component");
    };
  }, []);

const logoutHandler = () => {
    localStorage.clear();
    sessionStorage.clear();
    setSResponse("");
    setIsAuthenticated(false);
    setAuthToken(null);
    setUserId(null);
    setProjects([]);
    socket.close();
    setSocket(null);
  };

  let timer;
  const resetTimer = () => {
    if(timer) clearTimeout(timer);
  }
  const handleLogoutTimer = () => {
     timer = setTimeout(() => {
       resetTimer();
       events.forEach((item) => {
        window.removeEventListener(item, resetTimer);
       });
    socket!==null && logoutHandler();
  }, 1800000);
  };
  useEffect(() => {
    events.forEach((item) => {
      window.addEventListener(item, () => {
      resetTimer();
      handleLogoutTimer();
      })
    })
  }, [socket]);

  useEffect(() => {
    // Script for Rb2B to track website visitors. Find it here: https://support.rb2b.com/en/articles/9117086-how-to-add-rb2b-s-script-to-react-js
    (function () {
      var reb2b = window.reb2b = window.reb2b || [];
      if (reb2b.invoked) return;
      reb2b.invoked = true;
      reb2b.methods = ["identify", "collect"];
      reb2b.factory = function (method) {
        return function () {
          var args = Array.prototype.slice.call(arguments);
          args.unshift(method); reb2b.push(args);
          return reb2b;
        };
      };
      for (var i = 0; i < reb2b.methods.length; i++) {
        var key = reb2b.methods[i];
        reb2b[key] = reb2b.factory(key);
      }
      reb2b.load = function (key) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
        var first = document.getElementsByTagName("script")[0];
        first.parentNode.insertBefore(script, first);
      };
      reb2b.SNIPPET_VERSION = "1.0.1";
      reb2b.load("7N850HPWLLN1");
    })();
  }, []);
  
  const onChangePassword = async(passwordObject) => {
    const newObject = {
      old_password: passwordObject.OldPassword.value,
      new_password: passwordObject.ConfirmNewPassword.value
    }
    try {
      const res = await api.post(
        `/change-password`,
         newObject
        );
      setPasswordChangeResponse(res.data)
    } catch (err) {
      if (err.response) {
        setShowToastr({
          show: true,
          type: "error",
          text: err?.response?.data?.message
        });
        console.error("Server responded with an error:", err.response);
      } else {
        console.error("Something went wrong:", err);
      }
      setPasswordChangeResponse({...passwordChangeResponse,message: err.response.data.message});
    }
  }

  useEffect(() => {
    const isCorrectHost = window.location.host.split(".")[0] === "scanner";
    if (isCorrectHost) {
      const preFilledUsername = process.env.REACT_APP_USER_EMAIL;
      const preFilledPassword = process.env.REACT_APP_USER_PASSWORD;
      // Automatically log in
      handleLogin(preFilledUsername, preFilledPassword).then(user_id => {
        if (user_id) {
          isDevEnvironment && console.log("Auto-login successful. Creating a project...");
          const projectName = "ephemeral_project_" + Math.random().toString(36).slice(2, 8); // example: jml_ephemeral_project_lk9nqv8z
          const projectDescription = "This is an ephemeral project created automatically";
          const handleGetProjects = (data) => {
            if (data?.success === false) {
              setShowToastr({
                show: true,
                type: "error",
                text: data?.error
              });
            }
           else if (data && data.projects) setProjects(data.projects);
          };

          const handleAddProject = (response) => {
            socket.removeEventListener("add_project", handleAddProject);
            if (response?.success === false) {
              setShowToastr({
                show: true,
                type: "error",
                text: response?.error
              });
            } else {
              const projectId = response.project_id ?? response;
              sessionStorage.setItem("jml_workspace_id", projectId);
              isDevEnvironment && console.log("Project created successfully with id:", projectId);
              socket.send("get_projects");
              socket.addEventListener("get_projects", handleGetProjects);
              var newProject = true;
              navigate(`/canvas/${projectId}`, {
                state: { projectId, projectName, newProject, sandboxMode: false },
              });
            }
          };

          if (socket) {
            if (!window.location.pathname.includes('/canvas')){
            socket.send("add_project", { project_name: projectName, project_description: projectDescription });
            socket.addEventListener("add_project", handleAddProject);
            }
          } else {
            console.error("WebSocket is not initialized.");
          }
        } else {
          isDevEnvironment && console.log("Auto-logirenderComponentBasedOnQueryn failed");
        }
      });
    }
  }, [socket]);

  const getQueryParams = (query) => {
    if (!query) return {};
    return query
      .substring(1)
      .split("&")
      .reduce((acc, param) => {
        const [key, value] = param.split("=");
        acc[key] = value !== undefined ? value : true;
        return acc;
      }, {});
  };

  const renderComponentBasedOnQuery = () => {
    const queryParams = getQueryParams(window.location.search);
    if ( queryParams.MyCloud || window.location.pathname.includes('/canvas')) {
      return (
        <RequireAuth>
          <NewSafeViewEditArchitecture
            sResponse={sResponse}
            qResponse={qResponse}
            setSResponse={setSResponse}
            setQResponse={setQResponse}
            descriptionId={descriptionId}
            socket={socket}
            loadingArch={loadingArch}
            setLoadingArch={setLoadingArch}
            logoutHandler={logoutHandler}
          />
        </RequireAuth>
      );
    }
  };

  if (window.location.host.split(".")[0] === "scanner") {
    return (
      <Routes>
        <Route path="/" element={renderComponentBasedOnQuery()} />
        <Route path="/canvas/:pathProjectId?" element={renderComponentBasedOnQuery()} />
      </Routes>
    );
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<UpdatedLanding />} />
        <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
        <Route path="/new-product" element={<NewArchProductPage />} />
        <Route path="/product" element={<ProductPage/>} />
        <Route path="/cloud-scanner" element={<CloudScannerProductPage/>} />
        <Route path="/cloud-scanner-userguide" element={<CloudScannerUserGuide />} />
        <Route path="/in-press" element={<InPress/>} />
        <Route path="/blogs" element={< Blogs/>} />
        {/* <Route path="/details" element={< Details/>} /> */}
        <Route path="/brochure/cloudx" element={<BrochuresIndex />} />
        <Route path="/flyer/cloudx" element={<FlyersIndex />} />
        <Route
          path="/adminregister"
          element={<AdminRegisterPage onLogin={handleAdminRegister} />}
        />
        <Route path="/change-password" element={
          <RequireAuth>
            <ChangePassword onChangePassword={onChangePassword} passwordChangeResponse={passwordChangeResponse}/>
          </RequireAuth>
          }/>
        <Route
          path="/redriveindexing"
          element={<RedriveIndexingPage onLogin={handleRedriveIndexing} />}
        />
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <NewDashboard
                socket={socket}
                logoutHandler={logoutHandler}
                sandboxMode={false}
              />
            </RequireAuth>
          }
        />
        <Route
          path="/canvas/:pathProjectId?"
          element={
            <RequireAuth>
              <NewSafeViewEditArchitecture
                sResponse={sResponse}
                qResponse={qResponse}
                setSResponse={setSResponse}
                setQResponse={setQResponse}
                descriptionId={descriptionId}
                socket={socket}
                loadingArch={loadingArch}
                setLoadingArch={setLoadingArch}
                logoutHandler={logoutHandler}
              />
            </RequireAuth>
          }
        />
        <Route
          path="/sandbox"
          element={
            <RequireAuth>
              <NewDashboard
                socket={socket}
                logoutHandler={logoutHandler}
                sandboxMode={true}
              />
            </RequireAuth>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
