import React, { useState } from "react";
import CreateContext from "./create-context";

const ContextProvider = (props) => {
  /**
   * states
   */
  const [theme, setTheme] = useState("light");
  const [showSpinner, setShowSpinner] = useState(false);
  /*For Top Navbar Active View*/
  const [activeSection, setActiveSection] = useState("");
  /*canvas*/
  const [highlightEdgesContext, setHightlightEdgesContext] = useState({
    key: null,
    nodes: [],
  }); /*For highlighting edges path*/
  const [highlightNodesContext, setHightlightNodesContext] = useState(
    []
  ); /*For highlighting nodes*/
  /*For Searching Node Name in Analysis Pane*/
  const [searchNode, setSearchNode] = useState(null);
  //For Navbar,Leftbar and Footer Bar Toggle
  const [showBars, setShowBars] = useState({
    showNavbar: true,
    showLeftSidebar: true,
    showFooter: true,
  });
  //set footer input text field
  const [footerSearchText, setFooterSearchText] = useState("");

  //project list state
  const [projects, setProjects] = useState([]);

  //set active button for footerbar
  const [footerBtn, setFooterBtn] = useState(null);

  // set the side bar active and deactive in canvas
  const [showDocSidebarCanvas, setDocSidebarCanvas] = useState(false)

  //Handlers
  /**
   * Toggle Theme
   */
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  /**
   * Spinner Handler
   */
  const openSpinnerHandler = () => {
    setShowSpinner(true);
  };

  const closeSpinnerHandler = () => {
    setShowSpinner(false);
  };

  /**
   * To use gptcache or not in the backend is driven by this state
   */
  const [useCache, setUseCache] = useState(true);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [edgeColor, setEdgeColor ] = useState();
  const [zoomVal, setZoomVal] = useState();
  const [userId, setUserId] = useState();
  const [queryStr, setQueryStr] = useState("");
  const [selectedInfraviewDetail, setSelectedInfraviewDetail] = useState([]);
  const [showToastr, setShowToastr] = useState({
    show: false,
    type: "", //error,success
    text: "",
  });
  const [isAnyApplicationSelected, setIsAnyApplicationSelected] = useState(false);
  const [matchedServices, setMatchedServices] = useState("");
  const [matchedNodeDetail, setMatchedDetails] = useState([])
  const [searchResults, setSearchbarResults] = useState([]);
  const [nodeLabel, setNodeLabel] = useState([]);
  const [showAddNewNodeModal, setShowAddNewNodeModal] = useState(null);

  /**
   *  Context Value
   */
  const context = {
    theme: theme,
    toggleTheme: toggleTheme,
    showSpinner: showSpinner,
    openSpinnerHandler: openSpinnerHandler,
    closeSpinnerHandler: closeSpinnerHandler,
    activeSection,
    setActiveSection,
    highlightEdgesContext,
    setHightlightEdgesContext,
    highlightNodesContext,
    setHightlightNodesContext,
    searchNode,
    setSearchNode,
    showBars,
    setShowBars,
    footerSearchText,
    setFooterSearchText,
    useCache,
    setUseCache,
    projects,
    setProjects,
    footerBtn,
    setFooterBtn,
    showDocSidebarCanvas,
    setDocSidebarCanvas,
    isTourOpen,
    setIsTourOpen,
    edgeColor,
    setEdgeColor,
    zoomVal,
    setZoomVal,
    userId,
    setUserId,
    showToastr,
    setShowToastr,
    isAnyApplicationSelected,
    setIsAnyApplicationSelected,
    queryStr,
    setQueryStr,
    selectedInfraviewDetail,
    setSelectedInfraviewDetail,
    setMatchedServices,
    matchedServices,
    matchedNodeDetail,
    setMatchedDetails,
    searchResults,
    setSearchbarResults,
    nodeLabel,
    setNodeLabel,
    showAddNewNodeModal,
    setShowAddNewNodeModal
  };

  window.context = context;

  return (
    <CreateContext.Provider value={context}>
      {props.children}
    </CreateContext.Provider>
  );
};

export default ContextProvider;
