import { useContext } from "react";
import PopOver from "../../../../common/components/popover/popover.component";
import { Popover as ReactBootstrapPopover } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import context from "../../../../store/create-context";
import CloseIcon from "../../../../images/icons/close";

const NodeEdgePopUp = ({
  isSidebarOpen,
  setIsSidebarOpen,
  selectedComponent,
  selectedNode,
  selectedEdge,
  selectedAssociation,
  target,
  highlightNodesFn,
  setSelectedNode,
}) => {
  //context
  const { setFooterSearchText } = useContext(context);

  /**
   * Attach Mark Up Events to the string
   */
  const renderHighlightedText = (str, markUpArr) => {
 //Render Text
    const setNewString = (text, indexOfHrefStart) => {
      const addedTargetString = text.substring(0, indexOfHrefStart)+ " target='_blank' " + text.substring(indexOfHrefStart);
      const checkSubGraphString = addedTargetString.includes("Code reference(s)");
      const newString = checkSubGraphString ? addedTargetString.replace("Code reference(s)", "Ref"): addedTargetString.replace("Code reference", "Ref");
      const indexOfRef = newString.indexOf("Ref");
      return newString.substring(0,indexOfRef) + newString.substring(indexOfRef);
    }
    const renderText = (text, index) => {
      const indexOfHrefStart = text.indexOf("href");
      const newText = indexOfHrefStart<0 ? text: setNewString(text, indexOfHrefStart);
      return <span key={index} className="nodeDesc" dangerouslySetInnerHTML={{ __html: newText }} />
    }

    //Render PopUp
    const renderPopover = (text, popupText, index) => {
      const popover = (
        <ReactBootstrapPopover
          key={index}
          id="popover-basic"
          style={{ background: "rgb(47, 47, 47)" }}
        >
          <ReactBootstrapPopover.Body>
            <p className="p-2">{popupText}</p>
          </ReactBootstrapPopover.Body>
        </ReactBootstrapPopover>
      );

      return (
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
          <span
            variant="success"
            style={{ color: "#02DC59", cursor: "pointer" }}
          >
            {text}
          </span>
        </OverlayTrigger>
      );
    };

    //Render JmlSearch
    const renderJmlSearch = (text, jmlSearch, index) => (
      <span
        key={index}
        style={{ color: "yellow", cursor: "pointer" }}
        onClick={() => setFooterSearchText(jmlSearch)}
      >
        {text}
      </span>
    );

    //Render Canvas HighLight
    const renderCanvasHighlight = (text, canvasHighlight, index) => (
      <span
        key={index}
        style={{ color: "red", cursor: "pointer" }}
        onClick={() => highlightNodesFn(canvasHighlight)}
      >
        {text}
      </span>
    );

    //Render Anchor Tag
    const renderAnchorTag = (text, href, index) => (
      <a
        key={index}
        href={href}
        target="_blank"
        style={{ color: "#0078D4", opacity: "0.7" }}
        rel='noreferrer'
      >
        {text}
      </a>
    );

    //Render Hover
    const renderHover = (text, hoverText, index) => (
      <span
        key={index}
        data-toggle="tooltip"
        data-placement="bottom"
        title={hoverText}
        style={{
          color: "#0000FF",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {text}
      </span>
    );

    //Render Markup
    const renderMarkup = (text, data, index) => {
      let type = data.type;
      switch (type) {
        case "href":
          return renderAnchorTag(text, data.href, index);
        case "hover":
          return renderHover(text, data.hover_text, index);
        case "popup":
          return renderPopover(text, data.popup_text, index);
        case "jml_search":
          return renderJmlSearch(text, data.jml_search, index);
        case "canvas_highlight":
          return renderCanvasHighlight(text, data.canvas_highlight, index);
        default:
          return renderText(text, index);
      }
    };

    /*split string based on wordsToSplit array*/
    let wordsToSplit = [];
    if (markUpArr && Array.isArray(markUpArr)) {
      markUpArr.forEach((item) => {
        wordsToSplit.push(item.text.trim());
      });
    }

    if (wordsToSplit.length === 0) return renderText(str);

    const regex = new RegExp(`(\\b${wordsToSplit.join("\\b|\\b")}\\b)`, "g");
    const parts = str.split(regex);

    return parts.map((part, index) => {
      const matchingMarkUp = markUpArr.find((markup) => markup.text === part);
      if (matchingMarkUp) {
        return renderMarkup(part, matchingMarkUp, index);
      } else {
        return renderText(part, index);
      }
    });
  };

  const renderInfo = (type) => {
    return (
      <div
        style={{
          width: "fit-content",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <div
          className="close-btn text-end"
          role="button"
          onClick={() => {
            setIsSidebarOpen(false);
            setSelectedNode(null);
          }}
        >
          <CloseIcon />
        </div>
        <div>
          {type === 0 && "Select any node or edge for more details about it."}
          {type === 1 && renderNodeInfo()}
          {type === 2 && renderEdgeInfo()}
        </div>
      </div>
    );
  };

  const renderNodeInfo = () => {
    //Check if any association of node selected
    let getSelectedAssociation = null;
    if (selectedAssociation !== "" && selectedAssociation != null) {
      let associations = selectedNode.data.associations;
      if (associations && associations.length > 0) {
        getSelectedAssociation = associations.find(
          (association) => association.id === selectedAssociation
        );
      }
    }

    /**
     * check if description_markup present first otherwise get description or info
     */

    let textString =
      selectedNode.description_markup && selectedNode.description_markup.text
        ? selectedNode.description_markup.text
        : selectedNode.description
        ? selectedNode.description
        : selectedNode.info || "No Data Found!";

    if (textString === undefined) {
      textString = "No Data Found!";
    }

    /**
     * check if description_markup markup array present
     */
    let arrMarkup = selectedNode?.description_markup?.markup ?? [];
    if (arrMarkup === undefined) {
      arrMarkup = [];
    }

    return (
      <>
        {getSelectedAssociation && <h5>{getSelectedAssociation.name}</h5>}
        {!getSelectedAssociation &&
          renderHighlightedText(textString, arrMarkup)}
      </>
    );
  };

  const renderEdgeInfo = () => {
    let textString =
      selectedEdge.data && selectedEdge.data.description_markup
        ? selectedEdge.data.description_markup.text
        : selectedEdge.data && selectedEdge.data.description
        ? selectedEdge.data.description
        : selectedEdge.info || "";

    if (textString === undefined) {
      textString = "";
    }
    let arrMarkup = selectedEdge?.data?.description_markup?.markup ?? [];
    if (arrMarkup === undefined) {
      arrMarkup = [];
    }

    return renderHighlightedText(textString, arrMarkup);
  };

  const renderSelectedComponentInfo = () => {
    switch (selectedComponent) {
      case 1:
        return renderInfo(1);
      case 2:
        return renderInfo(2);
      default:
        return renderInfo(0);
    }
  };

  return (
    <PopOver
      isSidebarOpen={isSidebarOpen}
      target={target}
      renderSelectedComponentInfo={renderSelectedComponentInfo}
    />
  );
};

export default NodeEdgePopUp;
