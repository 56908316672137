import "./CloudScanner.css";
import vector from "../../../../../images/Vector .svg";
import Infraview_askAI from "../../../../../images/infra_ask_ai.svg";
import checkImg from "../../../../../images/check-broken.svg";
import highlighted_infra from "../../../../../images/highlights-infra.svg";
import sync from "../../../../../images/synicon.svg";
import security from "../../../../../images/security.svg";
import integraionAI from "../../../../../images/integration_Ai.svg";
import comprehesive from "../../../../../images/comprehensive.svg";
import InfraScannerCarousel from "./InfraScannerCarousel";
import Footer from "../../../../NewLanding2/Footer";
import Navbar from "../../../../NewLanding2/Navbar";
import CloudScannerMobile from "./CloudScannerMobile";
import { useEffect, useState } from "react";

const Index = () => {
  const [show, setShow] = useState(false);
  const[isMobile, setIsMobile] = useState(window.innerWidth <= 425)

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 425;
      setIsMobile(isMobile);
      if (!isMobile) {
        setShow(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigateToInfraScanner = () => {
    if (isMobile) {
      setShow(true);
    } else {
      setShow(false);
      const currentHostname = window.location.hostname;
      const currentPort = window.location.port ? `:${window.location.port}` : '';
      const newHostname = `scanner.${currentHostname.replace('www.', '')}`;
      const newUrl = `${window.location.protocol}//${newHostname}${currentPort}/?MyCloud`;
      window.open(newUrl, '_blank');
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: '#e9edf5' }}>
        <Navbar/>
      </div>
      {/* <Header /> */}

      <div className="container">
        <div className="product-title">World's Most Comprehensive<sup>*</sup></div>
        <strong className="title-strong">AWS Cloud Scanner</strong>
        <div className="image-container">
          <img className="image" src={vector} alt="Vector" />
        </div>
        <div className="description-container">
          <p className="description">
            Use AI To Get Full Visibility Of Your Cloud Resources
            <br/>In Under 60 Seconds
            <br/>For Free! 
          </p>
        </div>
        <button
            className="join-btn cloud-scanner-tryIt"
            type="button"
            onClick={navigateToInfraScanner}>
            Try it!
        </button>
        <p style={{ fontSize: '16px' }}>
          (No Login Required)
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="codeView-img">
        <img src={Infraview_askAI} alt="codeView_frame" className="code-view-img"/>
        </div>
      </div>
      <p style={{ fontSize: '12px'}}><em>*in terms of number of services supported</em></p>
      <InfraScannerCarousel />
      {/* Use case section */}
      <div className="usecase-section">
        <div className="inner-container">
          <div className="use-cases">
            <div className="usecase-title">
              <span><img src={checkImg} alt="check-broken" /></span>
              <span><strong>Key Benefits</strong></span>
            </div>
            <span>
              <img src={highlighted_infra} alt="highlighted_infra" className="highlighted-img"/>
            </span>
          </div>
        </div>
      </div>
    {/* Benefits section */}
      <div className="key-benefits-container">
      <div className="key-benefits-heading">
        <span>
          <strong>Use Cases</strong>
        </span>
      </div>
      <div className="key-benefits-row">
        <div className="key-benefit-card card-sync">
          <span>
            <img src={sync} alt="synicon" />
          </span>
          <span>
            <strong>DevOps</strong>
          </span>
          <span>
            The natural language query capability allows devops engineers to get software and resource answers when the experts are not available at 3 am.
          </span>
        </div>
        <div className="key-benefit-card card-security">
          <span>
            <img src={security} alt="security" />
          </span>
          <span>
            <strong>Security & Compliance</strong>
          </span>
          <span>
            Use asset inventories and natural language queries to provide contextual information for incident responders, managed service providers, and auditors.
          </span>
        </div>
      </div>
      <div className="key-benefits-row">
        <div className="key-benefit-card card-ai">
          <span>
            <img src={integraionAI} alt="integration_AI" />
          </span>
          <span>
            <strong>IT Support</strong>
          </span>
          <span>
            Use asset inventories with drift monitoring to find shadow IT, unauthorized and orphaned resources.
          </span>
        </div>
        <div className="key-benefit-card card-comprehensive">
          <span>
            <img src={comprehesive} alt="comprehensive" />
          </span>
          <span>
            <strong>Finance</strong>
          </span>
          <span>
            Enable financial analysts to investigate cost anomalies without involving engineers. See costs for all the services and regions that are being used.
          </span>
        </div>
      </div>
      <br/>
      <br/>
      <button
        className="join-btn cloud-scanner-tryIt"
        type="button"
        onClick={() => {
          if (isMobile) {
            setShow(true);
          } else {
            setShow(false);
            window.open('https://scanner.jigsawml.com/?MyCloud', '_blank');
          }
        }}
      >
        Try it. No Login Reqd!
      </button>
      <br/>
      <br/>
    </div>
    <Footer />
    <CloudScannerMobile show ={show} setShow={setShow} />
    </div>
  )
}

export default Index;
