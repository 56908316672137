import { useContext, useEffect, useState } from "react";
import crossImg from "../../../images/clear.svg";
import searchImg from "../../../images/searchIcons.svg";
import "./canvas.component.css";
import CreateContext from "../../../store/create-context";
import { resourceIcon } from "./Regions";
import { addChildrens, highlightText } from "./InfraStructure";
import { addChildrensOfSubnet, getSubnetData } from "./Subnet";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import WebSocketInstance from "../../../common/WebSocketInstance";
import expandBtn from "../../../images/expand.svg";
export const processInfraData = (infraViewResponse, searchBar) => {
  if (!infraViewResponse) {
    return;
  }
  const accontInfra = infraViewResponse.nodes.filter(
    (el) => el.type === "aws:account"
  );
  const regionInfra = infraViewResponse.nodes.filter(
    (el) => el.type === "aws:region"
  );
  const regionData = regionInfra && addChildrens(infraViewResponse, regionInfra);

  regionData.map((region) => {
    const azInfra = region.total_region_count.filter((el) => el.type === "aws:az");
    const vpcInfra = region.total_region_count.filter((el) => el.type === "ec2:vpc");
    const azInfraArr = addChildrens(infraViewResponse, azInfra);
    const vpcInfraArr = addChildrens(infraViewResponse, vpcInfra);

    let subnetDataObject = [];
    let vpcChild = [];
    let azChild = [];
    let subChild = [];

    azInfraArr.forEach((az) => {
      vpcInfraArr.forEach((vpc) => {
        const subnet = getSubnetData(vpc.id, az.id, infraViewResponse);
        subnetDataObject.push(...subnet);
      });
    });

    azInfraArr.forEach((az) => {
      if (az.children && az.children.child.length !== 0) {
        azChild.push(...az.children.child);
      }
    });

    vpcInfraArr.forEach((vpc) => {
      if (vpc.children && vpc.children.child.length !== 0) {
        vpcChild.push(...vpc.children.child);
      }
    });

    const subArr = addChildrensOfSubnet(subnetDataObject, infraViewResponse);
    subArr.forEach((sub) => {
      if (sub.children && sub.children.child.length !== 0) {
        subChild.push(...sub.children.child);
      }
    });

    region.subnetDataObject = subnetDataObject;
    region.vpcChild = vpcChild;
    region.azChild = azChild;
    region.subChild = subChild;

    return region;
  });

  const filteredResults = searchBar
    ? infraViewResponse.nodes.filter((node) => {
        accontInfra.forEach((account) => {
          if (node.data.resource_id === account.data.resource_id) {
            node.matchedAccount = account.data.resource_id;
          }
          regionData.forEach((region) => {
            if (account.data.resource_id === region.parent) {
              if (node.data.resource_id === region.data.resource_id) {
                node.matchedRegion = region.name;
                node.matchedAccount = account.data.resource_id;
              }
              region.total_region_count.forEach((rg) => {
                if (node.parent === rg.parent) {
                  node.matchedRegion = region.name;
                  node.matchedAccount = account.data.resource_id;
                }
              });

              region.subnetDataObject.forEach((sub) => {
                if (node.data.resource_id === sub.data.resource_id) {
                  node.matchedRegion = region.name;
                  node.matchedAccount = account.data.resource_id;
                }
              });
              region.vpcChild.forEach((vpc) => {
                if (node.data.resource_id === vpc.data.resource_id) {
                  node.matchedRegion = region.name;
                  node.matchedAccount = account.data.resource_id;
                }
              });
              region.azChild.forEach((az) => {
                if (node.data.resource_id === az.data.resource_id) {
                  node.matchedRegion = region.name;
                  node.matchedAccount = account.data.resource_id;
                }
              });
              region.subChild.forEach((subChild) => {
                if (node.data.resource_id === subChild.data.resource_id) {
                  node.matchedRegion = region.name;
                  node.matchedAccount = account.data.resource_id;
                }
              });
            }
          });
          return node;
        });

        return (
          node.type.toLowerCase().includes(searchBar.toLowerCase()) ||
          node.name.toLowerCase().includes(searchBar.toLowerCase())
        );
      })
    : [];
  return filteredResults;
};


const SearchBar = ({
    nodes,
    handleNodeClick,
    selectNodeForInfra,
    setSelectNodeForInfra,
    searchBar,
    setSearchbar,
    infraViewResponse,
    selectNodeForLinking,
    setOpen,
    open
}) => {
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [searchResultNode, setSearchResultsNode] = useState([]);
    const { searchResults, setSearchbarResults, setNodeLabel} = useContext(CreateContext);
    const socket = WebSocketInstance.getInstance();

    useEffect(() => {
      const filteredNodes = searchBar
      ? nodes.filter(node =>
          node.type.toLowerCase().includes(searchBar.toLowerCase()) ||
          node.data.label.toLowerCase().includes(searchBar.toLowerCase())
      )
      : [];
      setSearchResultsNode(filteredNodes)
      const resultNodeLabel = filteredNodes.map((el) => { return el.data.label })
      setNodeLabel(resultNodeLabel)
    },[searchBar])

    useEffect(() => {
      const handleKeyDown = (e) => {
        const totalResults = searchResultNode.length + searchResults.length;
        if (e.key === "ArrowDown") {
          setFocusedIndex((prevIndex) => (prevIndex + 1) % totalResults);
        } else if (e.key === "ArrowUp") {
          setFocusedIndex((prevIndex) => (prevIndex - 1 + totalResults) % totalResults);
        } else if (e.key === "Enter" && focusedIndex >= 0 && selectNodeForInfra && !selectNodeForInfra["node-type"]) {
          const selectedItem = focusedIndex < searchResultNode.length ? searchResultNode[focusedIndex] : searchResults[focusedIndex - searchResultNode.length];
          handleNodeClick(e, selectedItem);
        }
      };
      const updateScroll = () => {
        if (focusedIndex >= 0) {
          const focusedElement = document.querySelector(
            `.search-code-view-result-item:nth-child(${focusedIndex + 1})`
          );
            focusedElement?.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      updateScroll();
      return () => {
          window.removeEventListener("keydown", handleKeyDown);
      };
    }, [focusedIndex, searchResultNode, searchResults, selectNodeForInfra]);

    useEffect(() => {
      if (!infraViewResponse) {
        return;
      }
      const filteredResults = processInfraData(infraViewResponse, searchBar);
      const filterInfraResources = filteredResults.filter((item) => {
        return item.type !== "aws:account" && item.type !== "aws:region" && item.type !== "aws:az";
      });
      setSearchbarResults(filterInfraResources);
    }, [searchBar, setSearchbarResults ]);

    const handleLinkResource = (item) => {
      socket.send("link_resource", {
        project_id: sessionStorage.getItem("jml_workspace_id"),
        code_view_node: selectNodeForLinking,
        infra_view_node: item
      });
    }

    document.getElementById("searchInput")?.focus();

    return (
      <>
      {!open &&
        <div
          className="search-btn"
          onClick={() => {
            setOpen(true)
          }}
        >
          <img src={searchImg} alt="Search" className="search-icon"/>
        </div>
      }
      <Collapse in={open} dimension="width" style={{height: "50px"}}>
        <div className="search-container">
          <img className="search-img" src={searchImg} alt="Search" />
          <input
            type="search"
            placeholder="Search"
            value={searchBar}
            className="search-input-box"
            id="searchInput"
            onChange={(e) => {
              setSearchbar(e.target.value)
              const str = selectNodeForInfra["node-type"]?.substring(0, selectNodeForInfra["node-type"].length - 1);
              setSelectNodeForInfra({ "node-type": str })
            }}
          />
          <span style={{width:'20px', marginLeft:'14px'}}>
          { searchBar &&
            <img className="clear-img" src={crossImg} alt="Clear"
              onClick={() => {
                setSearchbar("")
                setSelectNodeForInfra({})
              }}
            />
          }
          </span>
          <span style={{cursor: "pointer", marginLeft:'12px'}}>
            <img src={expandBtn} alt="expand" onClick={() => setOpen(false)}/>
          </span>
          {searchBar &&
            <div className="search-code-view-results">
              {selectNodeForInfra && selectNodeForInfra["node-type"] ?
                searchResults.map((item, index) => (
                <OverlayTrigger
                  placement="auto"
                  container={document.querySelector('.search-code-view-results')}
                  overlay={<Tooltip id="button-tooltip">Click to link this resource</Tooltip>}>
                  <div key={index}
                    className={`search-code-view-result-item ${index === focusedIndex ? "focused" : ""}`}
                    onClick={() => handleLinkResource(item)}
                  >
                    <span>
                      {resourceIcon(
                        item.type?.includes(":")
                        && item.type.split(":")[1]
                      )}
                    </span>
                    <span>
                      {highlightText(item && `${item.type} (${item.name}) in ${item?.matchedRegion} of
                      ${item?.matchedAccount}`, searchBar)}
                    </span>
                  </div>
                </OverlayTrigger>
                ))
                :
                searchResultNode.length > 0 && (
                  searchResultNode.map((node, index) => (
                    <div key={index} className={`search-code-view-result-item ${index === focusedIndex ? "focused" : ""}`}
                      onClick={(e) => handleNodeClick(e, node)}
                    >
                      <p>{highlightText(node.type + " (" + node.data.label + ")", searchBar)}</p>
                    </div>
                  ))
                )
              }
            </div>
          }
        </div>
      </Collapse>
    </>
    );
}

export default SearchBar;
