import React, { useEffect, useState, useRef, useContext } from "react";
import { Container } from "react-bootstrap";
import styles from "./view-edit-architecture.module.css";
import Frame from "./Frame";
import NavbarUI from "./NavbarUI";
import LeftSidebar from "./LeftSidebar";
import DataTour from "./components/DataTour/DataTour";
// import GuideTour from "../../../common/components/GuideTour/GuideTour";
// import steps from "../../../common/components/GuideTour/steps";
import context from "../../../store/create-context";
import FeedbackButton from "./FeedbackButton";
import SyncButton from "../../../images/syncBtn.png";


const ViewEditArchitecture = (props) => {
  //context
  const { showBars, setIsTourOpen, activeSection, isAnyApplicationSelected} = useContext(context);

  useEffect(()=>{
    if(props.newProject) {
      setIsTourOpen(true);
    }
  }, [props.newProject])

  //states
  const [pendingActivity, setPendingActivity] = useState(false);
  const [checkActivity, setCheckActivity] = useState(false);
  const [checkCloudActivity, setCheckCloudActivity] = useState(false);
  const [sidebarData, setSidebarData] = useState({});
  const [items, setItems] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const childRef = useRef(null);

  const downloadPDF = () => {
    childRef.current.frameFn();
  };

  const isScanner = window.location.host.split(".")[0] === "scanner";

  return (
    <>
      <Container fluid className={activeSection === "infra_view" || isScanner ? styles["container-fluid"]: styles["container-fluid2"]}>
        {showBars.showNavbar && (
          <NavbarUI
            sResponse={props.sResponse}
            projectName={props.projectName}
            projectDescription={props.projectDescription}
            setSResponse={props.setSResponse}
            downloadPDF={downloadPDF}
            pendingActivity={pendingActivity}
            setPendingActivity={setPendingActivity}
            checkActivity={checkActivity}
            setCheckActivity={setCheckActivity}
            logoutHandler={props.logoutHandler}
            sandboxMode={props.sandboxMode}
            sidebarData={sidebarData}
            checkCloudActivity={checkCloudActivity}
            setCheckCloudActivity={setCheckCloudActivity}
          />
        )}

        <div className="d-flex flex-row h-100">

          {Object.keys(props.sResponse).length > 0 && showBars.showLeftSidebar && (activeSection !== "infra_view" || isAnyApplicationSelected)  && (
            <DataTour
              tourDataId="reactour__analysis_pane"
              style={{ transform: "rotate(100deg)", top: "2%", left: "50%" }}
            >
              <div style={{ width: "78px" }}>
                <LeftSidebar sidebarData={sidebarData} />
              </div>
            </DataTour>
          )}

          {props.newProject && Object.keys(props.sResponse).length === 0 && window.location.host.split(".")[0]!== "scanner" && (<div className="welcome-message" style={{
              padding: "20px",
              textAlign: "center",
              color: "white",
              position: "fixed",
              top: "150px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "rgba(18,20,31,1)",
              borderRadius: "10px",
              width: "85%", // Cap max width to avoid overflow on large screens
              fontSize: "1vw", // Scale the font size with the viewport
            }}>
              <h3>Welcome to your new Project!</h3>
              <p>Click any of the buttons below to get started.</p>
              <br/>
              <article>
                <p>🚀 <strong>Quick Start:</strong> We've set up a couple of repositories and cloud accounts for you. Access them through the buttons below.
                  <br/>⏳ <em>Setup time for these samples is approximately 5 minutes.</em> Feel free to grab a ☕ during the time.</p>
                <p>Want to use your own repository or cloud account? No problem!
                  <br/>📝 <strong>Note:</strong> We currently support Python and JavaScript applications built on AWS only.
                  <br/>(Support for more coming soon)</p>
                <p>For feedback, reach out to us at <a href="mailto:info@jigsawml.com">info@jigsawml.com</a></p>
            </article>
          </div>
          )}
          {Object.keys(props.sResponse).length === 0 && (window.location.host.split(".")[0]=== "scanner" && window.location.pathname.includes('/canvas')) && (<div className="welcome-message" style={{
              padding: "20px",
              textAlign: "center",
              color: "white",
              position: "fixed",
              top: "150px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "rgba(18,20,31,1)",
              borderRadius: "10px",
              width: "85%", // Cap max width to avoid overflow on large screens
              fontSize: "1vw", // Scale the font size with the viewport
            }}>
              <h2>Welcome to JigsawML's Cloud Scanner</h2>
              <br/>
              <br/>
              <article>
                <div style={{ marginBottom: "25px" }}>
                  <h4>To get started:</h4>
                  <p>
                    Click the <img alt="Sync Button" src={SyncButton} style={{ height: '2em', width: 'auto', verticalAlign: 'middle' }} /> button to connect to a demo cloud account. You will have the option of connecting to your own cloud account.
                    <br></br>
                    Connections finish in &lt; 60 seconds, but in rare cases, may take couple of minutes.
                    <br></br>
                    A step-by-step guide is <a href="https://jigsawml.com/cloud-scanner-userguide" target="_blank" rel="noopener noreferrer">here</a>.
                    <br></br>
                  </p>
                </div>
                <div style={{ marginBottom: "25px" }}>
                  <h4>Heads up!</h4>
                  <p>
                    <span style={{ backgroundColor: '#3A36FF' }}>We do not store your data!</span><br/>
                    This is meant as a quick tool. So,
                    <br/>1. if we restart our servers, your data will be lost.
                    <br/>2. if you navigate away from this page, your data will be lost.
                    <br/>3. you can download the summary pdf of your accounts if you wish.
                    <br/>4. to retain your data for longer term, consider joining our Beta program.
                  </p>
                </div>
                <div>
                  <h4>Join our Beta</h4>
                  <p>
                    ➡️ Local on-premise installer also available!
                    <br/>
                    ➡️ Experience even more <span style={{ backgroundColor: '#3A36FF' }}>powerful Multi-Agent AI</span> to manage your cloud infrastructure efficiently. 
                    <br/>
                    ➡️ Automatically create interactive <span style={{ backgroundColor: '#3A36FF' }}>software architecture diagrams</span> like you've never seen before.
                    {" "}<a href="https://jigsawml.com/product" target="_blank" rel="noopener noreferrer">More</a>.
                    <br/>
                    ➡️ We are also creating an <span style={{ backgroundColor: '#3A36FF' }}>AI-For-Cloud Advisory group</span>. To know more or join, please email <a href="mailto:info@jigsawml.com">info@jigsawml.com</a>.
                  </p>
                </div>
            </article>
          </div>
          )}
          <div className="flex-grow-1">
              {!props.sandboxMode && (activeSection !== "infra_view" && activeSection !== "code_view")  &&(
                <div style={{position:'absolute', bottom:'75px', right:'220px'}}>
                  <FeedbackButton/>
                </div>
              )}
              <Frame
                {...props}
                sResponse={props.sResponse}
                ref={childRef}
                pendingActivity={pendingActivity}
                setPendingActivity={setPendingActivity}
                setSidebarData={setSidebarData}
                items={items}
                setItems={setItems}
                searchLoader={searchLoader}
                setSearchLoader={setSearchLoader}
                setCheckActivity={setCheckActivity}
                sandboxMode={props.sandboxMode}
                checkCloudActivity={checkCloudActivity}
                setCheckCloudActivity={setCheckCloudActivity}
              />
          </div>
        </div>
        {/* {props.newProject && <GuideTour isOpen={true} steps={steps} />} */}
      </Container>
    </>
  );
};

export default ViewEditArchitecture;
